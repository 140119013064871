var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("upload-files-alert", { attrs: { meta: _vm.applicant.meta } }),
      _c("applicant-files", {
        attrs: {
          files: _vm.applicant.files,
          applicant_id: _vm.applicant.applicant_id
        },
        on: { valid: _vm.complete, update: _vm.update }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }