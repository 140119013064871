<template>
    <div>
        <upload-files-alert :meta="applicant.meta"></upload-files-alert>
        <applicant-files
            :files="applicant.files"
            :applicant_id="applicant.applicant_id"
            @valid="complete"
            @update="update"
        ></applicant-files>
    </div>
</template>
<script>
export default {
    name: "upload-files",
    components: {
        UploadFilesAlert: () => import("./UploadFilesAlert"),
        ApplicantFiles: () => import("./ApplicantFiles"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        complete() {
            this.$emit("update", {
                complete: true,
            });
        },
        update(files) {
            this.$emit("update", {
                applicant: {
                    files,
                },
            });
        },
    },
};
</script>

<style scoped>
</style>